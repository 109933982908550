import React, { useState, useCallback, useEffect } from "react";
import "./Chat.css";
import "./AdaptiveCards.css";
import ReactWebChat, {
  createDirectLine,
  createStore,
} from "botframework-webchat";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

import AgentHeader from "../Header/AgentHeader";
import { Redirect } from "react-router-dom";
// import Menu from "../Menu/Menu";
// import FeedbackButtons from "/FeedbackButtons/FeedbackButtons";
// import FeedbackButtons from "../FeedbackButtons/FeedbackButtons";

// import CustomChatUi from "./CustomChatUi";

// const MenuButton = (props) => {
//   return (
//     <div className="menuButton" onClick={props.toggleMenu}>
//       <FontAwesomeIcon icon={props.menu ? faMinus : faPlus} />
//     </div>
//   );
// };

//const Chat = () => {
  /*let userName;
  useEffect(() => {
    userName = sessionStorage.getItem("userName") || "User";
  }, []);*/
  const setStyle = () => {
    return {
      botAvatarImage: "AE-bot.jpg",
      botAvatarInitials: "AE",
      // userAvatarInitials: "WC",
      // backgroundColor: "#fff",
      backgroundColor: "#fffafa",

      // botAvatarBorderStyle: "solid",
      // botAvatarBorderWidth: 2,
      // botAvatarBorderColor: "rgb(94, 110, 234)",
      // botAvatarBackgroundColor: "#000",

      userAvatarImage: "img_avatar.png",

      // bubbleBackground: "#FBFBFB",
      // bubbleFromUserBackground: "#DCF8C5",
      // bubbleFromUserBorderColor: "#ac9a9a",
      // bubbleFromUserBorderStyle: "solid",
      // bubbleFromUserBorderRadius: 0,
      // paddingRegular: 0,
      // paddingWide: 0,

      //bubble

      bubbleBorderWidth: 0,
      bubbleFromUserBorderWidth: 0,

      bubbleBorderRadius: 1,
      bubbleFromUserBorderRadius: 0,

      bubbleBackground: "transparent",
      bubbleFromUserBackground: "transparent",
      // bubbleFromUserBackground: "rgb(0, 206, 203)",

      // bubbleNubSize: 5,
      // bubbleFromUserNubSize: 5,

      //bottom

      transcriptOverlayButtonBackground: "rgba(94, 110, 234, .6)",
      transcriptOverlayButtonBackgroundOnFocus: "rgba(94, 110, 234, .8)",
      transcriptOverlayButtonBackgroundOnHover: "rgba(94, 110, 234, .8)",
      transcriptOverlayButtonColor: "White",
      transcriptOverlayButtonColorOnFocus: undefined, // defaults to transcriptOverlayButtonColor
      transcriptOverlayButtonColorOnHover: undefined, // defaults to transcriptOverlayButtonColor

      // suggestedActionBackground: "#00A7E1",
      suggestedActionBackground: "#c281cd",
      suggestedActionBorder: undefined, // split into 3, null
      suggestedActionBorderColor: "#fff", // defaults to accent
      suggestedActionBorderStyle: "solid",
      suggestedActionBorderWidth: 2,
      suggestedActionBorderRadius: "50px",
      suggestedActionImageHeight: 20,
      suggestedActionTextColor: "White",
      suggestedActionDisabledBackground: undefined, // defaults to suggestedActionBackground
      suggestedActionDisabledBorder: null,
      suggestedActionDisabledBorderColor: "#E6E6E6",
      suggestedActionDisabledBorderStyle: "solid",
      suggestedActionDisabledBorderWidth: 2,
      suggestedActionDisabledTextColor: undefined, // defaults to subtle
      suggestedActionHeight: 38,
      hideUploadButton: true,

      sendBoxBackground: "#ebebed",
    };
  };

  const AEMessage = (props) => (
    <div
      className={`message message-${props.user} msg msg-${props.user}`}
      // style={{ transition: "transform 0.2s ease 0s, margin 0.2s ease 0s" }}
    >
      <span className="message-content msg-content">{props.text}</span>
    </div>
  );
  const attachmentMiddleware = () => (next) => (card) => {
    if (card.attachment) {
      switch (card.attachment.contentType) {
        case "text/markdown":
          return <AEMessage user="operator" text={card.attachment.content} />;
        case "text/plain":
          return <AEMessage user="visitor" text={card.attachment.content} />;
        default:
          return next(card);
      }
    } else {
      return <AEMessage user="operator" text={card.text} />;
    }
  };

  // For Reaction Buttons

  // const [chatData, setChatData] = useState({});
  const activityStatusMiddleware = () => (next) => (args) => {
    const { activity, nextVisibleActivity, sendState, sameTimestampGroup } = args;
    // setChatData({ ...chatData, [activity.from.id]: activity });
    if (activity.from.role === "bot") {
      if (sendState === "sending") {
        return (
          <div className="activityStatus__wrapper">
            <span className="activityStatus activityStatus__sendStatus">
              Sending&hellip;
            </span>
            {/* <FeedbackButtons
              key={args.activity.id}
              activityID={args.activity.id}
            ></FeedbackButtons> */}
          </div>
        );
      } else if (sendState === "send failed") {
        return (
          <div className="activityStatus__wrapper">
            <span className="activityStatus">Send failed.</span>
            {/* <FeedbackButtons
              key={args.activity.id}
              activityID={args.activity.id}
            ></FeedbackButtons> */}
          </div>
        );
      } else if (!sameTimestampGroup) {
        return (
          <div className="activityStatus__wrapper">
            <span className="activityStatus activityStatus__timestamp">
              <span className="activityStatus__sender">@CogniBot</span>
              <span className="activityStatus__timestampContent">
                {next(args)}
              </span>
            </span>
            {/* <FeedbackButtons
              key={args.activity.id}
              activityID={args.activity.id}
            ></FeedbackButtons> */}
          </div>
        );
      }
    } else {
      if (!sameTimestampGroup) {
        return (
          <div className="activityStatus__wrapper">
            <span className="activityStatus activityStatus__timestamp">
              <span className="activityStatus__sender">@{sessionStorage.getItem("userName")}</span>
              <span className="activityStatus__timestampContent">
                {next(args)}
              </span>
            </span>
          </div>
        );
      }
    }
    return next(args);
  };

  // For Toggling Menu
  // const [menu, setMenu] = useState(false);

  // const toggleMenu = () => {
  //   setMenu(!menu);
  // };
  let interval;
  const store = window.WebChat.createStore(
    {},
    ({ dispatch }) => next => action => {
      /*if (action.type === 'WEB_CHAT/SEND_MESSAGE') {
        clearTimeout(interval);
      }*/
      if( action.type === 'DIRECT_LINE/INCOMING_ACTIVITY' ){
        action.payload.activity.timestamp = (new Date()).toISOString();
        /*clearInterval(interval);
        console.log(interval);
        if(interval === undefined || (interval !== undefined && interval < 45)){
          interval = setTimeout(() => {
            // Notify bot the user has been inactive
            dispatch({
              type: 'WEB_CHAT/SEND_EVENT',
              payload: {
                name: 'inactive',
                value: ''
              }
            });
          }, 300000);
        }*/
      }
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        /*setTimeout(() => {
          dispatch({
            type: 'WEB_CHAT/SEND_EVENT',
            payload: {
              name: 'webchat/join',
              value: {
                "firstName": sessionStorage.getItem("firstName"),
                "lastName": sessionStorage.getItem("lastName"),
                "emailId": sessionStorage.getItem("emailId"),
                "username": sessionStorage.getItem("userName"),
                "password": sessionStorage.getItem("password"),
                "role": sessionStorage.getItem("role")
              }
            }
          });
        }, 1000);*/
      }
      return next(action);
    }
  );
class AgentChat extends React.Component{
  render(){
    /*if(sessionStorage.getItem("loggedIn") === "false" || sessionStorage.getItem("loggedIn") == null){
      return <Redirect to="/"/>
    }*/
    return (
      <>
        <AgentHeader store={store} />
        <div className="chatWindow">
          <ReactWebChat
            attachmentMiddleware={attachmentMiddleware}
            // activityMiddleware={activityMiddleware}
            activityStatusMiddleware={activityStatusMiddleware}
            styleOptions={setStyle()}
            directLine={createDirectLine({
              //token: "9g56uhF0RHQ.8R_SWqBO3ce4Su5QJfmComEjq0HGd7dg0IcPj-WpTaI",
              //domain: "https://chatbot.automationedge.ai/v3/directline"
              token: "secret",
              //domain: "https://botdesk.automationedge.ai/v3/directline"
              //domain: "http://10.51.23.21/v3/directline"
              //domain:"http://localhost:8000/v3/directline"
              domain: "https://botdesk.automationedge.ai/v3/directline"
            })}
            store={store}
            username={sessionStorage.getItem("userName")}
          />
          {/* <MenuButton toggleMenu={toggleMenu} menu={menu} /> */}
        </div>
        {/* <Menu showMenu={menu} store={store} /> */}
      </>
    );
  }
};

export default AgentChat;