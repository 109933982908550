import React, { useState, useEffect } from "react";
import "./Header.css";
import { Redirect } from "react-router-dom";

// const menuClick = (index) => {
//   const menu = [
//     "User Guide",
//     "Documents",
//     "Reset Password",
//     "Raise Query",
//     "FAQs",
//   ];
//   alert(menu[index] + " clicked");
//   toggleNav();
// };

const sendMenuMessage = (store, message, toggleMenu) => {
  store.dispatch({
    type: 'WEB_CHAT/SEND_MESSAGE',
    payload: { text: message }
  });
  toggleMenu();
}

const SideNav = (props) => {
  // let [isNavOpen, setNavOpen] = useState(false);

  // useEffect(() => {
  //   toggleNav();
  // }, [props.onClick]);

  // const toggleNav = () => {
  //   if (isNavOpen) {
  //     closeNav();
  //   } else {
  //     openNav();
  //   }
  //   isNavOpen = setNavOpen(!isNavOpen);
  // };
  // const openNav = () => {
  //   document.getElementById("menusidenav").style.width = "100%";
  // };

  // const closeNav = () => {
  //   document.getElementById("menusidenav").style.width = "0";
  // };

  return (
    // <div id="menusidenav" className="sidenav" onLoad={toggleNav}>
    <div id="menusidenav" className="sidenav">
      {/* <div
        className="wc-message-groups"
        role="log"
        tabIndex="0"
        style={{ transform: "translateY(0px)" }}
      > */}
        <div
          className="wc-message-group-content"
          style={{ marginTop: "0px", display: "none" }}
        ></div>
        <div id="divMainMenu">
          <div className="menu-container">
            <div className="menu-inner-container">
              <div className="menu-dashboard">
                <div className="options">
                  <div className="menu-item" onClick={()=>sendMenuMessage(props.store, "User Guide", props.toggleMenu)}>
                    <div className="padded-container">
                      <div className="option color1">
                        <img alt="icon" src="user-guide.svg" />
                      </div>
                      <div className="text aetext">User Guide</div>
                    </div>
                  </div>
                  <div className="menu-item" onClick={()=>sendMenuMessage(props.store, "Documents", props.toggleMenu)}>
                    <div className="padded-container">
                      <div className="option color1">
                        <img alt="icon" src="docs.svg" />
                      </div>
                      <div className="text aetext">Documents</div>
                    </div>
                  </div>
                  <div className="menu-item" onClick={()=>sendMenuMessage(props.store, "Reset Password", props.toggleMenu)}>
                    <div className="padded-container">
                      <div className="option color1">
                        <img alt="icon" src="password-reset.svg" />
                      </div>
                      <div className="text aetext">Reset Password</div>
                    </div>
                  </div>
                  <div className="menu-item" onClick={()=>sendMenuMessage(props.store, "Raise Query", props.toggleMenu)}>
                    <div className="padded-container">
                      <div className="option color1">
                        <img alt="icon" src="email.svg" />
                      </div>
                      <div className="text aetext">Raise Query</div>
                    </div>
                  </div>
                  <div className="menu-item" onClick={()=>sendMenuMessage(props.store, "FAQs", props.toggleMenu)}>
                    <div className="padded-container">
                      <div className="option color1">
                        <img alt="icon" src="question_answer.svg" />
                      </div>
                      <div className="text aetext">FAQs</div>
                    </div>
                  </div>
                  <div className="menu-item" onClick={()=>sendMenuMessage(props.store, "Live Agent", props.toggleMenu)}>
                    <div className="padded-container">
                      <div className="option color1">
                        <img alt="icon" src="support_agent.svg" />
                      </div>
                      <div className="text aetext">Live Agent</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-questions">
              <div>
                <div style={{ marginTop: "5px", textAlign: "center" }}>
                  {" "}
                  You can ask things like:
                </div>
                <div className="h-rule"></div>
                <ul>
                  <li>How to rename a worksheet</li>
                  <li>Barcode field in adobe acrobat</li>
                  <li>how to configure folder sync</li>
                  <li>How to apply a theme in MS Excel 2010</li>
                  <li>How to check Voice Mail in BlackBerry Storm</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      {/* </div> */}
    </div>
  );
};
// const Header = ({ showSideNav, handleOnClick }) => {
const Header = (props) => {
  const [showNav, setShowNav] = useState(false);
  const toggleMenu = () => {
    setShowNav(!showNav);
  };
  const logout = () => {
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("password");
    sessionStorage.setItem("loggedIn", false);
    sessionStorage.removeItem("emailId");
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("lastName");
    window.location.reload(false);
  }
  return (
    <>
      <div className="chat_header">
        {/* <span
          style={{ fontSize: "24px", cursor: "pointer" }}
          onClick={toggleMenu}
        >
          &#9776;{" "}
        </span> */}
        <span>CogniBot</span>
        <div id="logout" onClick={logout}>
          <img src="/logout.png" height="100%"></img>
          <span height="100%" id="logout-span">Sign Out</span>
        </div>
      </div>
      {/* {showSideNav ? <SideNav></SideNav> : null} */}
      {showNav ? <SideNav store={props.store} toggleMenu={toggleMenu}></SideNav> : null}
    </>
  );
};

export default Header;
