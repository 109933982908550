import React from "react";
import "./App.css";
import { Route, Switch } from "react-router-dom";
import Welcome from "./components/Welcome/Welcome";
import AgentWelcome from "./components/Welcome/AgentWelcome";
// import Chat from "./components/Chat/Chat";
import Chat2 from "./components/Chat2/Chat";
import AgentChat from "./components/Chat2/AgentChat";
// import AEChat from "./components/AEChat/AEChat";

function App() {
  return (
    <div className="full-height">
      <Switch>
        <Route path="/" exact component={Welcome}></Route>
        {/* <Route path="/chat" exact component={Chat}></Route> */}
        <Route path="/chat2" exact component={Chat2}></Route>
        {/* <Route path="/aechat" exact component={AEChat}></Route> */}
        <Route path="/welcome" exact component={Welcome}></Route>
        <Route path="/agentChat" exact component={AgentChat}></Route>
        <Route path="/agent" exact component={AgentWelcome}></Route>
      </Switch>
    </div>
  );
}

export default App;
