const welcomeDetails = {
  coverLogo: "/ae-logo.png",
  logo: "/welcome_Chatbot.svg",
  title: "Hello, I am CogniBot for IT.",
  subtitle: "",
  summary: "I can help you with IT requests, queries and issues. Let me know how I can help you today.",
  UserNamePlaceholder: "User Name",
  PasswordPlaceholder: "Password",
  EmailPlaceholder: "Email",
  buttonText: "Get Started",
  resetButtonText: "Reset Password",
  signinFooter: "Forgot Password?",
  resetPwdFooter: "Sign In?"
};

export default welcomeDetails;