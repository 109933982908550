import React, { useState } from "react";
import "./Welcome.css";

import welcomeDetails from "./WelcomeDetails";

const AgentWelcome = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [formStatus, setFormStatus] = useState("signin");
  const [message, setMessage] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const handleSubmit = (event) => {
    event.preventDefault();
    // props.history.push("/aechat");
    /*sessionStorage.removeItem("userName");
    sessionStorage.setItem("userName", userName);
    sessionStorage.removeItem("password");
    sessionStorage.setItem("password", password);
    const axios = require("axios").default;
    setButtonStatus(true);
    //axios.post("http://localhost:9000/authenticate",{
    axios.post("https://botdesk.automationedge.ai/authenticate",{
    //axios.post("http://10.51.23.21/authenticate",{
      userName: userName,
      password: password
      }).then((response)=>{
        if(response.data["status"] === "success"){
          sessionStorage.setItem("loggedIn", true);
          sessionStorage.removeItem("emailId");
          sessionStorage.setItem("emailId", response.data["emailId"])
          sessionStorage.removeItem("role");
          sessionStorage.setItem("role", response.data["role"]);
          sessionStorage.removeItem("firstName");
          sessionStorage.setItem("firstName", response.data["firstName"])
          sessionStorage.removeItem("lastName");
          sessionStorage.setItem("lastName", response.data["lastName"]);
          props.history.push("/chat2");
          setButtonStatus(false);
        }
        else {
          sessionStorage.setItem("loggedIn", false);
          sessionStorage.removeItem("emailId");
          sessionStorage.removeItem("role");
          sessionStorage.removeItem("password");
          setPassword("");
          document.getElementById("warning-message").style.visibility = "visible";
          setButtonStatus(false);
        }
      }).catch((err) => {
        console.log(err);
        setButtonStatus(false);
      });
      console.log(buttonStatus);*/
      props.history.push("/agentChat");
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    const axios = require("axios").default;
    setMessage("You request is being submitted...");
    axios.post("https://botdesk.automationedge.ai/resetADPassword",{
    //axios.post("http://10.51.23.21/resetADPassword",{
      email: email
      }).then((response)=>{
        if(response.data["status"] === "success"){
          setMessage("Your request has been submitted. You ticket ID: "+response.data["TicketNumber"]);
        }else{
          setMessage("Sorry! Couldn't submit your request.")
        }
      }).catch((err) => console.log(err));
    
    setEmail("");
  }

  const switchForms = () => {
    if(formStatus == "signin"){
      setFormStatus("forgotPassword");
      document.getElementById("cred-form").style.display = "none"
      document.getElementById("forget-pwd-form").style.display = "block";
    }
    else{
      setFormStatus("signin");
      document.getElementById("forget-pwd-form").style.display = "none";
      document.getElementById("cred-form").style.display = "block";
    }
  }
  const handleUsernameChange = (event) => setUserName(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);

  const skipPage = () => {
    props.history.push("/chat");
  };

  return (
    <div className="wrapper">
      <div className="icon-background">
        <img
          alt="ae-logo"
          className="ae-icon"
          src={welcomeDetails.coverLogo}
        ></img>
      </div>
      <div className="bot-icon">
        <img alt="welcome-chat" src={welcomeDetails.logo}></img>
      </div>
      <div className="bot-info">
        <section className="bot-section">
          <h2 className="bot-title">{welcomeDetails.title}</h2>
          {welcomeDetails.subtitle ? (
            <p className="title">{welcomeDetails.subtitle}</p>
          ) : null}
          <p className="bot-summary">{welcomeDetails.summary}</p>
          <div className="user-name-input-window">
            <form
              className="name-input-container"
              onSubmit={(e) => handleSubmit(e)}
              id = "cred-form"
            >
              <div id="warning-message" className="warning-message" visibility="hidden"><font color="red">Invalid credentials!</font></div>
              <div>
                <input
                  type="text"
                  placeholder={welcomeDetails.UserNamePlaceholder}
                  autoComplete="off"
                  id="name-input"
                  className="name-input"
                  value={userName}
                  onChange={handleUsernameChange}
                ></input>
              </div>
              <div>
                <input
                type="password"
                placeholder={welcomeDetails.PasswordPlaceholder}
                autoComplete="off"
                id="name-input"
                className="name-input"
                value={password}
                onChange={handlePasswordChange}
              ></input></div>
              <div id="footer" onClick={switchForms}><u>{welcomeDetails.signinFooter}</u></div>
              <div className={{ width: 200 }} className="actions">
                <button type="submit" className="start-chat" disabled={buttonStatus}>
                  {welcomeDetails.buttonText}
                </button>
              </div>
            </form>
            <form
              className="name-input-container"
              onSubmit={(e) => handleEmailSubmit(e)}
              id = "forget-pwd-form"
            >
              <div><font>{message}</font></div>
              <input
                type="email"
                placeholder={welcomeDetails.EmailPlaceholder}
                autoComplete="off"
                id="name-input"
                className="name-input"
                value={email}
                onChange={handleEmailChange}
              ></input>
              <div id="footer" onClick={switchForms}><u>{welcomeDetails.resetPwdFooter}</u></div>
              <div className={{ width: 200 }} className="actions">
                <button type="submit" className="start-chat button-disabled" id="start-chat-button">
                  {welcomeDetails.resetButtonText}
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AgentWelcome;
